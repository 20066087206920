import React from 'react';

// CSS
import './style.css'

function index() {
    return (
        <div className="topography col-lg-12">
            
        </div>
    );
}

export default index;